@import '@fontsource/work-sans/latin.css';
@import '@fontsource/poppins/latin.css';
@import 'swiper/css';
@import 'swiper/css/pagination';
@import 'swiper/css/navigation';
@import './antd.css';
@import './swiper.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  @apply flex grow flex-col;
}

body {
  @apply m-0 bg-black font-sans leading-normal text-white antialiased;
  overflow-wrap: anywhere;
}

*,
::before,
::after {
  @apply box-border border-0 border-solid border-current;
}
