/* Prefer modifying theme variables in craco.config.js */

/* Collapse */
.ant-collapse-header {
  @apply !p-0;
}
.ant-collapse-content-box {
  @apply !p-0;
}
.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  @apply right-0;
}

/* Popover */
.ant-popover-inner-content {
  @apply p-0;
}
.ant-popover-arrow {
  @apply hidden;
}
.ant-popover-inner {
  @apply overflow-hidden rounded-[var(--popover-border-radius)];
}

/* Spin */
.ant-spin {
  @apply text-[color:inherit];
}
.anticon-spin {
  @apply text-[length:inherit];
}

/* Modal */
.ant-modal-content {
  @apply rounded-[length:var(--modal-border-radius)];
}
